<template>
    <div id="box">
        <dv-full-screen-container class="box">
            <img :src="Bg" alt="" style="position: absolute;width: 100%;height: 100%;opacity: 1">
            <div id="main" >
                <TopHeader></TopHeader>
                <div class="data-wrap">
                    <div class="left">
                        <div class="columnBox">
                            <Box style="height: 29%">
                                <Total :amount="sum_statistic"></Total>
                            </Box>
                            <Box style="height: 69%">
                                <div style="display: flex;width: 100%;height: 100%">
                                    <div style="width: 100%;height: 100%" id="topPi"></div>
                                    <div style="width: 100%;height: 100%" id="centerPi"></div>
                                </div>
                            </Box>
                        </div>
                        <div class="columnBox">
                            <Box style="height: 49%" title="学员分布" mini="1">
                                <div style="width: 100%;height: 100%" id="bottomPi"></div>
                            </Box>
                            <Box style="height: 49%" title="休假学员" mini="1">
                                <DataBox class="three" style="width: 100%;height: 100%" :config="leave"></DataBox>
                            </Box>

                        </div>

                    </div>
                    <div class="center">
                        <div class="columnBox">

                            <Box style="height: 100%" title="店铺统计" mini="1">
                                <div id="totalChart" style="width: 100%;height: 100%"></div>
                            </Box>
                        </div>

                        <Box class="columnBox">
                            <DataBox style="width: 100%;height: 100%" class="w-3" title="学员统计"
                                     :config="totalConfig"></DataBox>
                        </Box>
                    </div>
                    <div class="right">
                        <div class="columnBox">

                            <Box style="height: 100%" title="导师出单数" mini="1">
                                <div style="width: 100%;height: 100%" id="topBar"></div>
                            </Box>
                        </div>
                        <div class="columnBox">
                            <Box style="height: 69%" title="导师出单金额" mini="1">
                                <div style="width: 100%;height: 100%" id="topLine"></div>
                            </Box>
                            <div style="height: 29%;display: flex;align-items: center;justify-content: space-between;width: 100%">
                                <Box title="出单纪录" style="height: 100%;width: 49.5%" mini="1">
                                    <DataBox class="deliver" :config="order"></DataBox>
                                </Box>
                                <Box style="height: 100%;width: 49.5%" title="交付纪录" mini="1">
                                    <DataBox class="deliver" :config="deliver"></DataBox>
                                </Box>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div
                    class="bottom-total">
                统计时间：{{ sum_statistic.time }} 本季度合计出单总数：{{ sum_statistic.number }}
                本季度合计总金额：￥{{ sum_statistic.amount }} 昨日出单数：{{ sum_statistic.yesterday_number }}
                昨日出单金额：￥{{ sum_statistic.yesterday_amount }}
            </div>
        </dv-full-screen-container>
    </div>
</template>

<script>
import Bg from '../assets/img/bg2.png'
import Box from '../components/box.vue'
import Total from '../components/total.vue'
import TopHeader from '../components/topHeader.vue'
import DataBox from '../components/dataBox.vue'
import * as echarts from 'echarts';

import {deliver, order, totalConfig, leave} from "@/data/config";
import {
	getOption,
	getTopPiOptions,
	getTotalOption,
	getData,
	getTotalConfig,
	getList,
	getHolidayConfig, getTopConfig
} from "@/data/methods";
import {thirtyTypes, threeTypes} from "@/data";

export default {
	name: 'ViewBox',
	components: {DataBox, Box, Total, TopHeader},
	props: ['token'],
	time: null,
	data() {
		return {
			Bg, order, deliver, totalConfig, leave, totalChart: null, sum_statistic: {number: '', amount: ''}
		}
	},
	methods: {
		async getViewData(is_real) {
			const token = this.token
			if (!token) {
				this.$emit('noLogin')
				return
			}

			const res = await getData({is_real})
			if (!res) {
				this.$emit('noLogin')
				return
			}
			const {
				out_order_top,
				sum_statistic_30day,
				sign_stage,
				sum_statistic,
				out_order_count,
				delivery_trainee_count,
				trainee_counts,
				holiday_stage, delivery_trainee_top, operate_statistic_day
			} = res
			this.totalConfig = getTotalConfig(sign_stage)
			this.leave = getHolidayConfig(holiday_stage)
			this.order = getTopConfig(order, out_order_top)
			this.deliver = getTopConfig(deliver, delivery_trainee_top)
			this.sum_statistic = sum_statistic
			this.totalChart.setOption(getTotalOption(sum_statistic_30day))
			this.topBar.setOption(getOption(operate_statistic_day, '订单数'))
			this.topLine.setOption(getOption(operate_statistic_day, '订单金额', 'line'))
			this.topPi.setOption(getTopPiOptions(getList(threeTypes, out_order_count), ''))
			this.centerPi.setOption(getTopPiOptions(getList(thirtyTypes, delivery_trainee_count), '60日交付占比'))
			this.bottomPi.setOption(getTopPiOptions(trainee_counts, null, 70))

		}
	},
	mounted() {
		clearInterval(this.time)
		let is_real = undefined
		const query = new URLSearchParams(window.location.search)
		if (query && query.get('t') > 0) {
			is_real = 'real'
		}
		setTimeout(() => {
			this.totalChart = echarts.init(document.getElementById('totalChart'))
			this.topPi = echarts.init(document.getElementById('topPi'))
			this.centerPi = echarts.init(document.getElementById('centerPi'))
			this.bottomPi = echarts.init(document.getElementById('bottomPi'))
			this.topBar = echarts.init(document.getElementById('topBar'))
			this.topLine = echarts.init(document.getElementById('topLine'))
			this.getViewData(is_real)
			this.time = setInterval(() => {
				this.getViewData(is_real)
			}, 300000)
		}, 100)


	},
	beforeDestroy() {
		clearInterval(this.time)
	}
}
</script>

<style scoped lang="scss">
#box {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;

}

.data-wrap {
  width: 100%;
  height: calc(100% - 100px);
  display: flex;
  position: relative;
  z-index: 9;
  align-items: flex-start;
  justify-content: space-between;

  .center, .left, .right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }
;

  .left {
    width: 25%;
  }

  .center {
    width: 49.4%;
  }

  .right {
    width: 25%;
  }
}

#main {
  width: 100%;
  height: calc(100% - 120px);
  padding: 0;
  box-sizing: border-box;
}

.box {
  position: relative;
  color: white;
  /*background-image: linear-gradient(#191970EE,#19197088);*/
}

.top {
  width: 100%;
  height: 40%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /*background: #42b983;*/
}

.bottom {
  width: 100%;
  height: 60%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /*background: #ff0000;*/
}

.columnBox {
  display: flex;
  height: 49.5%;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
}

.bottom-total {
  position: relative;
  z-index: 999;
  text-align: center;
  width: 100%;
  font-size: 60px;
  padding: 20px
}

@media screen and (max-width: 2000px) {
  .bottom-total {
    font-size: 24px;
    padding: 10px 5px
  }
  #main {
    height: calc(100% - 60px);
  }
  .data-wrap {

    height: calc(100% - 60px);

  }
}
</style>
