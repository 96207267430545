<template>
  <div
      class="box"
      style="width: 100%;height: 100%;background: #fff;display: flex;align-items: center;justify-content: center">
    <div style="display: flex;flex-direction: column;align-items: center;justify-content: flex-start">
      <h1 style="color: #030409">请登录</h1>
      <input type="text" v-model="user" placeholder="请输入邮箱">
      <input type="password" v-model="password" placeholder="请输入密码">
      <button @click="login">登录</button>
    </div>
  </div>
</template>

<script>
import {loginApi} from "@/data/methods";

export default {
  name: "LoginBox",
  data() {
    return {
      user: '',
      password: ''
    }
  },
  methods: {
    login() {
      const {user, password} = this
      if (!user) {
        alert('请输入账号')
        return
      }
      if (!password) {
        alert('请输入密码')
        return
      }
      loginApi({user, password}).then(res => {
        localStorage.setItem('token', res.token)
        this.$emit('login')
      })
    }
  }

}
</script>

<style scoped lang="scss">
.box {
  width: 100%;
  height: 100%;

  input {
    -webkit-appearance: none;
    width: 400px;
    height: 50px;
    border-radius: 4px;
    margin: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    outline: none;
    font-size: 28px;
    padding: 0 15px;
  }

  button {
    -webkit-appearance: none;
    box-sizing: border-box;
    width: 400px;
    height: 50px;
    border: 1px solid #ccc;
    outline: none;
    background: #222;
    color: #fff;
    margin: 10px;
    border-radius: 4px;
    font-size: 35px;
  }
}

</style>
