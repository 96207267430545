export const preparation = {
  header: ['客户名', '签单日期', '时间', '销售名'],
  data: [
    ['牛贝2', '2022-11-11 ', '8天', '张三丰'],
    ['牛贝3', '2022-11-11 ', '8天', '张三丰'],
    ['牛贝4', '2022-11-11 ', '8天', '张三丰'],
    ['牛贝5', '2022-11-11 ', '8天', '张三丰'],
    ['牛贝6', '2022-11-11 ', '8天', '张三丰'],
    ['牛贝7', '2022-11-11 ', '8天', '张三丰'],
    ['牛贝8', '2022-11-11 ', '8天', '张三丰'],
    ['牛贝9', '2022-11-11 ', '8天', '张三丰'],
    ['牛贝1', '2022-11-11 ', '8天', '张三丰'],
    ['牛贝0', '2022-11-11 ', '8天', '张三丰']
  ],
  columnWidth: [150, 300, 150, 150],
  oddRowBGC: 'transparent',
  evenRowBGC: 'transparent',
  headerBGC: 'transparent',
  rowNum: 9
}
export const train = {
  header: ['客户名', '签单日期', '时间', '销售'],
  data: [
    ['牛贝2', '2022-11-11 ', '8天', '张三'],
    ['牛贝3', '2022-11-11 ', '8天', '李四'],
    ['牛贝4', '2022-11-11 ', '8天', '张三丰'],
    ['牛贝5', '2022-11-11 ', '8天', '张三丰'],
    ['牛贝6', '2022-11-11 ', '8天', '张三丰'],
    ['牛贝7', '2022-11-11 ', '8天', '张三丰'],
    ['牛贝8', '2022-11-11 ', '8天', '张三丰'],
    ['牛贝9', '2022-11-11 ', '8天', '张三丰'],
    ['牛贝1', '2022-11-11 ', '8天', '张三丰'],
    ['牛贝0', '2022-11-11 ', '8天', '张三丰']
  ],
  columnWidth: [150, 300, 150, 150],
  oddRowBGC: 'transparent',
  evenRowBGC: 'transparent',
  headerBGC: 'transparent',
  rowNum: 9
}
export const totalConfig = {
  header: ['客户名', '日期', '时长', '类型','店铺数', '累计金额/单', '昨日金额/单', '销售', '导师'],
  data: [
    ['牛贝2', '2022-11-11 ', '1', '筹备期',2, '-', '-', '张三', '-'],
    ['牛贝3', '2022-11-11 ', '8', '孵化期', 2,'1000/3', '300/1', '张三', '李四'],
    ['牛贝4', '2022-11-11 ', '9', '创业期',2, '1000/3', '300/1', '张三', '李四'],
    ['牛贝5', '2022-11-11 ', '18', '筹备期',2, '-', '-', '张三', '-'],
    ['牛贝6', '2022-11-11 ', '27', '培训期', 2,'-', '-', '张三', '李四'],
    ['牛贝7', '2022-11-11 ', '33', '孵化期',2, '1000/3', '300/1', '张三', '李四'],
    ['牛贝8', '2022-11-11 ', '22', '筹备期', 2,'-', '-', '张三', '-'],
    ['牛贝9', '2022-11-11 ', '11', '创业期',2, '1000/3', '300/1', '张三', '李四'],
    ['牛贝1', '2022-11-11 ', '3', '筹备期', 2,'-', '-', '张三', '-'],
    ['牛贝0', '2022-11-11 ', '18', '孵化期',2, '1000/3', '300/1', '张三', '李四']
  ],
  columnWidth: [200, 380, 180, 200,200, 480,380, 200, 200, 150],
  align:['center','center','center','center','center','center','center','center','center','center','center'],
  oddRowBGC: 'rgba(96,178,255,0.2)',
  evenRowBGC: 'rgba(96,178,255,0.24)',
  headerBGC: 'rgba(96,133,255,0.6)',
  rowNum: 9,
  headerHeight:70
}
export const hatch = {
  header: ['客户名', '签单日期', '时间', '累计金额/单', '昨日金额/单', '销售', '导师'],
  data: [
    ['牛贝2', '2022-11-11 ', '8天', '1000/3', '300/1', '张三', '李四'],
    ['牛贝3', '2022-11-11 ', '8天', '1000/3', '300/1', '张三', '李四'],
    ['牛贝4', '2022-11-11 ', '8天', '1000/3', '300/1', '张三', '李四'],
    ['牛贝5', '2022-11-11 ', '8天', '1000/3', '300/1', '张三', '李四'],
    ['牛贝6', '2022-11-11 ', '8天', '1000/3', '300/1', '张三', '李四'],
    ['牛贝7', '2022-11-11 ', '8天', '1000/3', '300/1', '张三', '李四'],
    ['牛贝8', '2022-11-11 ', '8天', '1000/3', '300/1', '张三', '李四'],
    ['牛贝9', '2022-11-11 ', '8天', '1000/3', '300/1', '张三', '李四'],
    ['牛贝1', '2022-11-11 ', '8天', '1000/3', '300/1', '张三', '李四'],
    ['牛贝0', '2022-11-11 ', '8天', '1000/3', '300/1', '张三', '李四']
  ],
  columnWidth: [150, 200, 150, 200, 200, 159, 159],
  oddRowBGC: 'transparent',
  evenRowBGC: 'transparent',
  headerBGC: 'transparent',
  rowNum: 9
}
export const leave = {
  header: ['客户名', '休假日期', '时间', '销售', '导师'],
  data: [
    ['牛贝2', '2022-11-11 ', '8天', '张三', '李四'],
    ['牛贝3', '2022-11-11 ', '8天', '张三', '李四'],
    ['牛贝4', '2022-11-11 ', '8天', '张三', '李四'],
    ['牛贝5', '2022-11-11 ', '8天', '张三', '李四'],
    ['牛贝6', '2022-11-11 ', '8天', '张三', '李四'],
    ['牛贝7', '2022-11-11 ', '8天', '张三', '李四'],
    ['牛贝8', '2022-11-11 ', '8天', '张三', '李四'],
    ['牛贝9', '2022-11-11 ', '8天', '张三', '李四'],
    ['牛贝1', '2022-11-11 ', '8天', '张三', '李四'],
    ['牛贝0', '2022-11-11 ', '8天', '张三', '李四']
  ],
  columnWidth: [200, 400, 200, 209, 209],
  align:['center','center','center','center','center','center','center','center','center','center','center'],
  oddRowBGC: 'transparent',
  evenRowBGC: 'transparent',
  headerBGC: 'transparent',
  rowNum: 5,
  headerHeight:60
}
export const tutor = {
  header: ['导师', '创业/休假/孵化', '累计金额/单', '昨日金额/单'],
  data: [
    ['张三', '3/8/5 ', '1000/3', '300/1'],
    ['张三', '3/8/5 ', '1000/3', '300/1'],
    ['张三', '3/8/5 ', '1000/3', '300/1'],
    ['张三', '3/8/5 ', '1000/3', '300/1'],
    ['张三', '3/8/5 ', '1000/3', '300/1'],
    ['张三', '3/8/5 ', '1000/3', '300/1'],
    ['张三', '3/8/5 ', '1000/3', '300/1'],
    ['张三', '3/8/5 ', '1000/3', '300/1'],
    ['张三', '3/8/5 ', '1000/3', '300/1'],
    ['张三', '3/8/5 ', '1000/3', '300/1']
  ],
  columnWidth: [150, 300, 150, 159, 159],
  oddRowBGC: 'transparent',
  evenRowBGC: 'transparent',
  headerBGC: 'rgba(96,133,255,0.6)',
  rowNum: 5
}
export const three = {

  data: [
    ['客户A(1)', '客户B(2)'],
    ['客户C(3)', '客户D(4)'],

  ],

  oddRowBGC: 'transparent',
  evenRowBGC: 'transparent',
  headerBGC: 'transparent',
  rowNum: 2,
  align: ['center', 'center']
}
export const thirty = {

  data: [
    ['客户A(19)', '客户B(27)'],
    ['客户C(32)', '客户D(41)'],

  ],

  oddRowBGC: 'transparent',
  evenRowBGC: 'transparent',
  rowNum: 2,
  align: ['center', 'center']
}
export const order = {

  data: [
    ['1天', '张三丰'],


  ],

  oddRowBGC: 'transparent',
  evenRowBGC: 'transparent',
  rowNum: 1,
  columnWidth: [300, 400, 100, 109, 109],
  align: ['center', 'center']
}
export const deliver = {

  data: [
    ['19天', '李四光'],


  ],
  columnWidth: [300, 400, 100, 109, 109],
  oddRowBGC: 'transparent',
  evenRowBGC: 'transparent',
  rowNum: 1,
  align: ['center', 'center']
}
