<template>
  <div class="box">
    <div>
      <div>
        <div class="title">Sales</div>
        <div class="prefix">￥</div>
      </div>
      <div class="num">{{ amount.amount }}</div>
    </div>
    <div>
      <div>
        <div class="title">Order</div>
        <div class="prefix">#</div>
      </div>
      <div class="num">{{ amount.number }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TotalBox",
  props: {
    amount:{
      type: Object,
      default(){
        return {}
      }
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  color: white;
  font-size: 70px;

  & > div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .title,.prefix {font-size: 40px;margin-right: 2px}
    .prefix {
      text-align: right;
    }
  }
}
@media screen and (max-width:2000px) {
  .box {

    padding: 5px;

    font-size: 35px;

    & > div {

      .title,.prefix {font-size: 20px;margin-right: 2px}

    }
  }
}
</style>
