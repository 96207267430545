<template>
  <div class="dataAllBorder01 cage_cl" v-bind="$attrs">
    <div class="dataAllBorder02" id="map_title_innerbox">
      <div class="map_title_box" v-if="title">
        <div class="map_title_innerbox">
          <div class="map_title" :class="{mini:mini}">{{ title }}</div>
        </div>
      </div>
      <div class="bootstrap-table data-box" :class="{allowTitle:title}">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import BG from './../assets/img/title.png'

export default {
  name: "boxB",
  props: ['title', 'mini'],
  data() {
    return {
      BG
    }
  }

}
</script>

<style scoped lang="scss">
.cage_cl {
  background-color: rgba(2, 8, 23, 0.1);
  margin: 0;
}

.dataAllBorder01 {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 1px #0174f5 solid;
  padding: 1px;
  box-sizing: border-box;
}

.dataAllBorder02 {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 2px solid #016ae0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.map_title_box {
  height: 11%;
  width: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(17, 25, 69, 0.1);
  border-radius: 11px 11px 0px 0px;
  position: relative;
}

.map_title_innerbox {
  position: absolute;
  top: -4px;
  width: 100%;
  height: 100%;
}

.map_title {
  width: 50%;
  background-repeat: no-repeat;
  background-image: url("./../assets/img/title.png");
  background-size: 100% 100%;
  margin: auto;
  padding: 5px 0;
  text-align: center;
  color: white;
  font-size: 64px;
  line-height: 1.5;
  font-family: "Microsoft YaHei";
  font-weight: bold;

  &.mini {
    font-size: 32px;
  }
}

.bootstrap-table {
  width: 100%;
  height: 100%;
}

.bootstrap-table.allowTitle {

  height: 87%;
}
@media screen and (max-width:2000px) {
  .map_title {
    font-size: 30px;
    &.mini {
      font-size: 16px;
    }
  }
}
</style>
