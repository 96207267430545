<template>
    <div id="top-header">
        <dv-decoration-8 class="header-left-decoration"/>
        <!--    <dv-decoration-2 class="header-center-decoration" />-->
        <dv-decoration-8 class="header-right-decoration" :reverse="true"/>
        <div class="center-title">{{ title }}</div>
    </div>
</template>

<script>
export default {
	name: 'TopHeader',
	data() {
		return {
			title: '牛贝众创数据分析'
		}
	},
	mounted() {
		function getQueryString(name) {
			let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i') // 正则语句
			let r = window.location.search.substr(1).match(reg) // 获取url的参数部分，用正则匹配
			if (r != null) {
				return decodeURIComponent(r[2]) // 解码得到的擦拭农户
			}
			return null
		}

		const n = getQueryString('name')
		if (n) {
			this.title = n
		}
	}
}
</script>

<style lang="scss">
#top-header {
  position: relative;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;

  .header-center-decoration {
    width: 32%;
    height: 40px;
    margin-top: 30px;
  }

  .header-left-decoration, .header-right-decoration {
    width: 32%;
    height: 40px;
  }

  .center-title {
    position: absolute;
    font-size: 80px;
    font-weight: bold;
    line-height: 90px;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 2000px) {
  #top-header {

    height: 50px;

    .header-center-decoration {
      height: 20px;
      margin-top: 15px;
    }

    .header-left-decoration, .header-right-decoration {

      height: 20px;
    }

    .center-title {

      font-size: 40px;

      line-height: 45px;

    }
  }
}
</style>
