<template>
  <dv-scroll-board :config="config" style="width:95%;height:100%;margin: 0 auto;font-size: 18px"/>
</template>

<script>

export default {
  name: "PreparationBox",
  components: {},
  props: {
    config: {
      type: Object,
      default() {
        return {
          header: ['客户名', '签单日期', '时间', '销售名'],
          data: [
            ['牛贝2', '2022-11-11 ', '8天', '朱广飞'],
            ['牛贝3', '2022-11-11 ', '8天', '朱广飞'],
            ['牛贝4', '2022-11-11 ', '8天', '朱广飞'],
            ['牛贝5', '2022-11-11 ', '8天', '朱广飞'],
            ['牛贝6', '2022-11-11 ', '8天', '朱广飞'],
            ['牛贝7', '2022-11-11 ', '8天', '朱广飞'],
            ['牛贝8', '2022-11-11 ', '8天', '朱广飞'],
            ['牛贝9', '2022-11-11 ', '8天', '朱广飞'],
            ['牛贝1', '2022-11-11 ', '8天', '朱广飞'],
            ['牛贝0', '2022-11-11 ', '8天', '朱广飞']
          ],
          columnWidth: [150, 300, 150, 150],
          rowNum: 9
        }
      }
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss">
.data-box .dv-scroll-board .rows .row-item {
  font-size: 40px;

}

.data-box .dv-scroll-board .header {
  background-image: -webkit-linear-gradient(left, rgba(96, 133, 255, 0.6), rgba(48, 69, 126, 0.6));
  font-size: 40px;
}

.data-box .dv-scroll-board.deliver .rows .row-item {
  font-size: 60px;
  font-weight: bold;

  .ceil {
    border-right: none;
  }

}

.data-box .three.dv-scroll-board .header {
  background-color: transparent;
  background-image: none;
  border-top: 1px solid #3490ba;
  font-size: 35px;
}
.data-box .three.dv-scroll-board .header .header-item {

  border-right: 1px solid #3490ba;
}

.data-box .three.dv-scroll-board .rows .row-item {
  font-size: 35px;

  border-top: 1px solid #3490ba;
}

.data-box .three.dv-scroll-board .rows .row-item .ceil {
  border-right: 1px solid #3490ba;
}
@media screen and (max-width:2000px) {
  .data-box .dv-scroll-board .rows .row-item,.data-box .dv-scroll-board .header {
    font-size: 14px;

  }
  .data-box .dv-scroll-board.deliver .rows .row-item{
    font-size: 24px;
  }
  .data-box .three.dv-scroll-board .header,.data-box .three.dv-scroll-board .rows .row-item {
    font-size: 14px;
  }
}
</style>
