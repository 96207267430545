<template>
  <div id="app">
    <div style="height: 100%;width: 100%">
      <ViewBox v-if="isLogin" :token="token" @noLogin="goToLogin"/>
      <LoginBox v-if="!isLogin" @login="getToken"/>
    </div>
  </div>
</template>

<script>

import ViewBox from './views/view'
import LoginBox from './views/login'


export default {
  name: 'App',
  components: {ViewBox, LoginBox},
  data() {
    return {
      token: '',
      isLogin: true
    }
  },
  methods: {
    getToken() {
      const token = localStorage.getItem('token')
      if (token) {
        this.token = token
        this.isLogin = true
      }
    },
    goToLogin(){
      localStorage.removeItem('token')
      this.isLogin = false
    }
  },
  mounted() {
    this.getToken()
  }

}
</script>

<style scoped lang="scss">
#app {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;

}
</style>
