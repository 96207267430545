const getSize = () => {
	const width = document.documentElement.clientWidth
	if (width > 2000) {
		return 1
	}
	return 0.4
}

export const totalOptions = {
	backgroundColor: '',

	tooltip: {
		trigger: 'axis',

	},
	calculable: false,

	legend: {
		itemHeight: 30* getSize(),
		itemWidth: 60* getSize(),
		textStyle: {
			color: '#fff',

			fontSize: 48 * getSize()
		}
	},
	xAxis:

		{
			show: true,
			type: 'category',
			color: '#fff',
			data: [],
			nameTextStyle: {
				fontSize: 28 * getSize()
			},
			axisLine: {
				show: true,
				lineStyle: {
					color: '#fff'
				},


			},
			axisLabel: {
				color: '#fff',

				fontSize: 28 * getSize()
			}
		}
	,
	yAxis: [

		{
			type: 'value',
			name: '总金额',
			nameTextStyle: {
				fontSize: 28 * getSize()
			},
			axisLine: {
				show: true,
				lineStyle: {
					color: '#fff'
				},


			},
			splitLine: {
				show: false
			},
			axisLabel: {
				formatter: '{value}',
				fontSize: 28 * getSize()
			}
		},

		{
			type: 'value',
			name: '订单数',
			splitLine: {
				show: false
			},
			axisLine: {
				show: true,
				lineStyle: {
					color: '#fff'
				},


			},
			nameTextStyle: {
				fontSize: 36 * getSize()
			},
			axisLabel: {
				formatter: '{value}',
				fontSize: 36 * getSize()
			}
		},
	],
	series: [

		{
			name: '总金额',
			type: 'line',
			lineStyle: {
				width: 5,
				color: '#FFDB5C'
			},

			itemStyle: {color: '#FFDB5C'},
			tooltip: {
				valueFormatter: function (value) {
					return value + '元';
				}
			},
			data: []
		},
		{
			name: '订单数',
			type: 'bar',
			tooltip: {
				valueFormatter: function (value) {
					return value + '单';
				}
			},
			yAxisIndex: 1,
			barWidth: '55%',
			data: []
		},
	]
}
export const initOptions = {
	backgroundColor: '',
	tooltip: {
		trigger: 'axis',

	},
	calculable: false,

	legend: {
		itemHeight: 30* getSize(),
		itemWidth: 60* getSize(),
		textStyle: {
			color: '#fff',
			fontSize: 36 * getSize()
		}
	},
	xAxis:
		{
			show: true,
			type: 'category',
			color: '#fff',
			data: [],
			nameTextStyle: {
				fontSize: 28 * getSize()
			},
			axisLine: {
				show: true,
				lineStyle: {
					color: '#fff'
				},


			},
			axisLabel: {
				color: '#fff',
				fontSize: 28 * getSize()
			}
		}
	,
	yAxis:
		{
			type: 'value',
			name: '总金额',
			axisLine: {
				show: true,
				lineStyle: {
					color: '#fff'
				},
			},
			splitLine: {
				show: false
			},
			axisLabel: {
				formatter: '{value}',
				fontSize: 24 * getSize()
			},
			nameTextStyle: {
				fontSize: 24 * getSize()
			},
		},
	series: [
		{
			name: '订单量',
			type: 'bar',
			tooltip: {
				valueFormatter: function (value) {
					return value + '单';
				}
			},
			lineStyle: {
				width: 5
			},
			barWidth: '35%',
			data: []
		}
	]
}
export const piOptions = {
	color: [
		'#32C5E9',
		'#FFDB5C',
		'#9FE6B8',
		'#96BFFF',


		'#ff9f7f',
		'#fb7293',
		'#E062AE',
		'#E690D1',

		'#9d96f5',
		'#8378EA',

	],
	title: {
		text: '10日出单占比',
		left: 'center',
		top: '10%',
		textStyle: {
			color: '#fff',
			fontSize: 48 * getSize()
		}
	},
	tooltip: {
		trigger: 'item',
		textStyle: {
			color: '#fff',
			fontSize: 48 * getSize()
		}
	},
	legend: {
		itemHeight: 30* getSize(),
		itemWidth: 60* getSize(),
		icon: 'circle',
		orient: 'vertical',
		left: 'left',
		top: 'bottom',
		textStyle: {
			color: '#fff',
			fontSize: 30 * getSize()
		}
	},
	series: [
		{
			name: '出单数',
			type: 'pie',
			radius: '44%',
			center: ['50%', '50%'],
			data: [
				{value: 1048, name: '已出单'},
				{value: 735, name: '未出单'},
			],
			label: {
				show: true,
				color: '#fff',
				fontSize: 24 * getSize(),
				alignTo: 'none',
				bleedMargin: 5,
				formatter: '{b}\n {d}%'
			},
			labelLine: {
				lineStyle: {
					width: 3
				}
			}
		}
	]
};
