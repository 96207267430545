import {clone} from "@/utils/clone";
import {initOptions, piOptions, totalOptions} from "@/data/options";
import {get, post} from "@/data/ajax";
import {leave, totalConfig} from "@/data/config";

const color = ['#1afffd', '#45c0ff', '#ffb400', '#2d95ed']

export const getData = (params) => {
	return new Promise((resolve, reject) => {
		get('api/trainee/data-view', params).then(res => {
			resolve(res.data)
		}).catch(res => {
			reject(res)
		})
	})
}
export const loginApi = (data) => {
	return new Promise(resolve => {
		post('api/data-view/login', data).then(res => {
			resolve(res)
		})
	})
}
export const getTopConfig = (init_config, list) => {
	const config = clone(init_config)
	const data = []
	const {day, operate_name} = list
	data.push([day + '天', operate_name])
	return {...config, data}
}
export const getTotalOption = (data) => {
	const option = clone(totalOptions)

	option.color = color
	const xAxis = option.xAxis
	xAxis.data = []
	const series = option.series
	series[0].data = []
	series[1].data = []
	Object.keys(data).forEach(key => {
		const item = data[key]
		series[0].data.push(item.amount)
		series[1].data.push(item.number)
		xAxis.data.push(key)
	})
	return {...option, series, xAxis}
}

export const getTotalConfig = (list) => {
	const config = clone(totalConfig)
	const data = []
	list.forEach(item => {
		const {
			nick_name,
			date,
			day,
			stage_name,
			market_num,
			amount,
			number,
			yesterday_amount,
			yesterday_number,
			seller_name,
			operate_name
		} = item
		data.push([nick_name || '-', date || '-', day || '-', stage_name || '-', market_num || '-', `${amount || '-'}/${number || '-'}`, `${yesterday_amount || '-'}/${yesterday_number || '-'}`, seller_name || '-', operate_name || '-'])
	})
	return {...config, data}
}
export const getHolidayConfig = (list) => {
	const config = clone(leave)
	const data = []
	// for (let i=0;i<Math.ceil(18/list.length);i++){
	for (let i = 0; i < 1; i++) {
		list.forEach(item => {
			const {
				nick_name,
				date,
				day,

				seller_name,
				operate_name
			} = item
			data.push([nick_name || '-', date || '-', day || '-', seller_name || '-', operate_name || '-'])
		})
	}

	return {...config, data}
}


export const getList = (list, data) => {

	list.forEach(item => {
		const code = item.code
		if (data[code]) {
			item.value = data[code]
		} else {
			item.value = 0
		}
	})
	return list
}
export const getTopPiOptions = (list, title, num) => {
	const option = clone(piOptions)
	if (title) {
		option.title.text = title

		option.legend.left = 'right'
	} else if (title === null) {
		option.title.show = false
	}
	const {series} = option
	series[0].data = []
	if (num) {
		series[0].radius = num + '%'
		series[0].center = ['50%', '50%']
	}
	list.forEach(item => {
		const {name, value} = item
		series[0].data.push({name, value: value})
	})
	return {...option, series}
}
export const getOption = (list, title, type = 'bar') => {
	const option = clone(initOptions)

	option.xAxis.data = []
	option.yAxis.name = title
	const seriesItem = option.series[0]
	const series = []


	list.forEach(tutor => {
		const {sum, operate_name} = tutor
		const item = clone(seriesItem)
		item.name = operate_name
		item.type = type
		sum.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
		item.barWidth = 100 / (sum.length + 1) + '%'
		item.data = []
		sum.forEach(data => {
			const {amount, number, date} = data
			if (option.xAxis.data.indexOf(date) < 0) {
				option.xAxis.data.push(date)
			}
			if (type === 'bar') {
				item.data.push(number)
			} else {
				item.data.push(amount)
			}
		})
		series.push(item)
	})
	option.series = series

	return option
}

