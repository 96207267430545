export const studentTypes = [
    {name: '筹备期', color: '#1aff3d', num: 137},
    {name: '培训期', color: '#FF4500', num: 287},
    {name: '创业期', color: '#FFD700', num: 489},
    {name: '孵化期', color: '#3D9140', num: 599},
]
export const threeTypes = [
    {name: '已出单', color: '#1aff3d', num: 193, code: 'count'},
    {name: '未出单', color: '#FF4500', num: 144, code: 'no_count'},
    {name: '休假中', color: '#FF4500', num: 41, code: 'holiday_count'},
]
export const thirtyTypes = [
    {name: '已交付', color: '#3D9140', num: 93, code: 'count'},
    {name: '未交付', color: '#FFD700', num: 44, code: 'no_count'},
    {name: '休假中', color: '#FF4500', num: 11, code: 'holiday_count'},
]
export const tutorList = [
    {name: '张三', amount: 1999.33, num: 137},
    {name: '李四', amount: 8997.1, num: 287},
    {name: '王五', amount: 9817, num: 489},
]
