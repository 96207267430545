export const baseUrl = 'https://jumbotron.niubeidata.com/'
export const ajax = (url, method, params = {}, body = {}) => {

    const {fetch, Object, localStorage} = window;
    const token = localStorage.getItem('token');
    const m = method.toUpperCase();
    const initData = {
        method: m,
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            token
        },
    };
    let paramsStr = '';
    if (m === 'GET' || m === 'DELETE' || m === 'PATCH') {
        paramsStr = '?';
        Object.keys(params).forEach(key => {
            if(params[key]!== undefined){
                paramsStr += key + '=' + params[key] + '&';
            }

        });
        if (paramsStr !== '?') {
            paramsStr = paramsStr.substr(0, paramsStr.lastIndexOf('&'));
        } else {
            paramsStr = '';
        }
    } else {
        initData.body = JSON.stringify(body);
    }
    if (m === 'PATCH') {
        initData.body = JSON.stringify(body);
    }
    return new Promise((resolve, reject) => {
        fetch(baseUrl + url + paramsStr, initData).then(response => {

            return response.json();
        }).then(response => {
            resolve(response);
        }).catch(res => {
            reject(res);
        });
    });
};
export const get = (url, params) => {
    return ajax(url, 'get', params);
};
export const post = (url, body) => {
    return ajax(url, 'post', {},body);
};
